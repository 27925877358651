<template>
  <section
    class="box-height consultantBonus"
    style="height: 100%; padding: 0 15px !important"
  >
    <div style="background-color: #fff; padding-top: 20px">
      <el-form :model="filter" ref="filter" label-width="100px">
        <el-row>
          <div>
            <el-form-item label="顾问：" prop="userIds" class="fl">
              <el-cascader
                style="width: 240px"
                clearable
                size="mini"
                filterable
                :show-all-levels="false"
                :options="options2"
                collapse-tags
                ref="dataRef"
                v-model="userIds"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  multiple: true,
                  checkStrictly: false,
                }"
                @change="getSearch"
                :filter-method="filterFun"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="奖金计算模型名称："
              prop="calculateModelName"
              class="fl"
              label-width="150px"
            >
              <el-select
                v-model="filter.modelIds"
                placeholder="请选择"
                filterable
                clearable
                collapse-tags
                multiple
                @change="search"
              >
                <el-option
                  v-for="(item, index) in nameList"
                  :key="'name_' + index"
                  :label="item.modelName"
                  :value="item.modelId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              type="primary"
              size="mini"
              @click="showDig"
              style="margin-left: 15px"
              >新增</el-button
            >
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div class="pd40" style="background-color: #fff; padding: 0px 0px 40px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
        >
          <el-table-column prop="userName" label="顾问" width="160">
          </el-table-column>
          <el-table-column prop="workId" label="工号"> </el-table-column>
          <el-table-column prop="departmentName" label="所属团队" width="120">
          </el-table-column>
          <el-table-column
            prop="calculateModelName"
            label="奖金计算模型名称"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            prop="fixedSalary"
            label="固定工资（元/月）"
            width="130"
          >
          </el-table-column>
          <el-table-column prop="startDate" label="入职时间" width="120">
          </el-table-column>
          <el-table-column label="入职月在职天数" width="130">
            <template #default="scope">
              {{
                scope.row.entryMonthFull ? "满月" : scope.row.entryMonthWorkDays
              }}
            </template>
          </el-table-column>
          <el-table-column prop="endDate" label="离职时间" width="120">
            <template #default="scope">
              {{ scope.row.endDate ? scope.row.endDate : "-" }}
            </template>
          </el-table-column>
          <el-table-column label="离职月在职天数" width="130">
            <template #default="scope">
              {{
                scope.row.leaveMonthFull
                  ? "满月"
                  : scope.row.leaveMonthWorkDays
                  ? scope.row.leaveMonthWorkDays
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="payrollEntity"
            label="发放主体"
            show-overflow-tooltip
            width="200"
          >
            <template #default="scope">
              {{ scope.row.payrollEntity ? scope.row.payrollEntity : "-" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="tabHref">编辑</span>
              <el-divider direction="vertical"></el-divider>
              <span @click="delectRow(scope.row.id)" class="tabHref">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
      <el-drawer
        :visible.sync="dialogVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">新增</div>
        <div class="drawerCon">
          <addconsultantBonus
            ref="addconsultantBonus"
            @changeSaveLoading="changeSaveLoading"
            @handleCloseDrawer="closeDig"
            @loadlist="search"
          ></addconsultantBonus>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="closeDig" size="small">取 消</el-button>
            <el-button
              type="primary"
              :loading="saveloading"
              size="small"
              @click="save"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
      <el-drawer
        :visible.sync="drawerVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig1"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">编辑</div>
        <div class="drawerCon">
          <el-table
            :data="editData"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            class="tabBorder custor pctable notableBorder"
            style="width: 100%"
          >
            <el-table-column prop="userName" label="顾问" width="180">
            </el-table-column>
            <el-table-column prop="workId" label="*工号">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.workId"
                  placeholder=""
                  maxlength="10"
                  style="width: 100%"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="*所属团队" min-width="180px">
              <template slot-scope="scope">
                <el-cascader
                  style="width: 100%"
                  :filterable="true"
                  :options="options1"
                  clearable
                  collapse-tags
                  :show-all-levels="false"
                  v-model="scope.row.departmentIds"
                  :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  placeholder="请选择所属团队"
                  ref="dataRef1"
                  class="dinputr"
                  @change="getDepartmentId()"
                  popper-class="selectCascaderPop"
                  :filter-method="filterFun"
                >
                </el-cascader>
              </template>
            </el-table-column>
            <el-table-column
              prop="calculateModelName"
              label="*奖金计算模型名称"
              min-width="180"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.calculateModelId"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in nameList"
                    :key="'name_' + index"
                    :label="item.modelName"
                    :value="item.modelId"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="fixedSalary"
              label="*固定工资（元/月）"
              width="180"
            >
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.fixedSalary"
                  placeholder="请填写数字"
                  style="width: 100%"
                  maxlength="16"
                  @blur="testInp"
                  @input="
                    (v) => (scope.row.fixedSalary = v.replace(/[^\d.]/g, ''))
                  "
                  type="munber"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="*入职时间"
              min-width="200px"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.startDate"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="*入职月在职天数" width="180">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <el-input
                    v-model="scope.row.entryMonthWorkDays"
                    placeholder="请填写数字"
                    :disabled="scope.row.entryMonthFull"
                    style="width: 100%; margin-right: 10px"
                    @blur="testInp"
                    @input="
                      (v) =>
                        (scope.row.entryMonthWorkDays = v.replace(
                          /[^\d.]/g,
                          ''
                        ))
                    "
                  ></el-input>
                  <el-checkbox
                    v-model="scope.row.entryMonthFull"
                    label="满月"
                    :disabled="
                      !scope.row.entryMonthFull &&
                      scope.row.entryMonthWorkDays !== '' &&
                      scope.row.entryMonthWorkDays!==null
                    "
                  ></el-checkbox>
                </div>
              </template>
              <!-- <template #default="scope">
                {{
                  scope.row.entryMonthFull
                    ? "满月"
                    : scope.row.entryMonthWorkDays
                }}
              </template> -->
            </el-table-column>
            <el-table-column prop="endDate" label="离职时间" min-width="200px">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.endDate"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  :picker-options="endDateDisable"
                ></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              prop="consultantType"
              label="离职月在职天数"
              min-width="200px"
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <el-input
                    v-model="scope.row.leaveMonthWorkDays"
                    placeholder="请填写数字"
                    :disabled="scope.row.leaveMonthFull"
                    style="width: 100%; margin-right: 10px"
                    @blur="testInp"
                    @input="
                      (v) =>
                        (scope.row.leaveMonthWorkDays = v.replace(
                          /[^\d.]/g,
                          ''
                        ))
                    "
                  ></el-input>
                  <el-checkbox
                    v-model="scope.row.leaveMonthFull"
                    label="满月"
                    :disabled="
                      !scope.row.leaveMonthFull &&
                      scope.row.leaveMonthWorkDays !== '' &&
                      scope.row.leaveMonthWorkDays!==null
                    "
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="payrollEntity"
              label="发放主体"
              min-width="200px"
            >
              <template slot-scope="scope">
                <el-input
                  maxlength="100"
                  v-model.trim="scope.row.payrollEntity"
                  placeholder="请填写内容"
                  style="width: 100%"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="drawerVisible = false" size="small"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="submit"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {
  permissionTree, //获取顾问
  bonusModelUserSettingPage, //顾问奖金设置分页
  bonusModelUserSettingDeleteSingle, //顾问奖金设置单个删除
  bonusModelUserSettingEditSingle, //顾问奖金设置单个修改
  bonusCalculateModelOptionList, //获取奖金计算模型下拉列表
} from "../../api/api";
import addconsultantBonus from "../../components/set/addconsultantBonus";
export default {
  components: {
    addconsultantBonus,
  },
  data() {
    return {
      filter: {
        modelName: "",
        userIds: [],
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
      },
      ListData: [],
      listLoading: false,
      options2: [],
      options1: [],
      dialogVisible: false,
      saveloading: false,
      drawerVisible: false,
      editData: [],
      userIds: [],
      nameList: [],
      endDateDisable: {
        disabledDate: this.endDisabled,
      },
    };
  },
  created() {
    this.getList();
    this.permissionTree("顾问"); // 获取用户的组织架构
    this.permissionTree("团队"); // 获取用户的组织架构
    this.getNameList();
  },
  methods: {
    testInp(e) {
      const pattern = /^\d{1,9}(\.\d{1,2})?$/;
      if (e.target.value && !pattern.test(e.target.value)) {
        this.$message.error("最多保留两位小数");
        return;
      }
    },
    endDisabled(time) {
      let start = new Date(this.editData[0].startDate).getTime();
      // console.log(time.getTime(),start)
      return time.getTime() < start;
    },
    //获取奖金计算模型下拉列表
    getNameList() {
      bonusCalculateModelOptionList().then((res) => {
        if (res.success) {
          this.nameList = res.result;
        }
      });
    },
    // 获取用户的组织架构
    permissionTree(type) {
      permissionTree({
        type: type,
        showDisableUser: true,
        showLeaveStatus: true,
      }).then((res) => {
        if (res.success) {
          if (type == "顾问") {
            this.options2 = res.result;
          } else {
            this.options1 = res.result;
          }
        }
      });
    },
    //搜索
    search() {
      this.filter.page.current = 1;
      this.filter.page.total = 0;
      this.getList();
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      // let isChecked;
      this.filter.userIds = this.userIds.map((i) => {
        return i[i.length - 1];
      });
      this.search();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    getDepartmentId() {
      this.editData[0].departmentId =
        this.editData[0].departmentIds[
          this.editData[0].departmentIds.length - 1
        ];
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.getList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.getList();
    },
    //获取列表数据
    getList() {
      this.listLoading = true;
      bonusModelUserSettingPage(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新增
    showDig() {
      this.dialogVisible = true;
    },
    //防抖节流
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    //关闭
    closeDig() {
      this.dialogVisible = false;
      this.saveloading = false;
    },
    //保存
    save() {
      this.$refs.addconsultantBonus.saveInfo();
    },
    //删除
    delectRow(id) {
      this.$confirm("确认删除吗?", "提示", {
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          bonusModelUserSettingDeleteSingle({ id: id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //关闭弹窗
    closeDig1() {
      this.drawerVisible = false;
    },
    //编辑
    handleEdit(row) {
      this.editData[0] = row;
      this.drawerVisible = true;
    },
    //设置表格
    submit() {
      bonusModelUserSettingEditSingle(this.editData[0]).then((res) => {
        if (res.success) {
          this.$message.success("编辑成功");
          this.drawerVisible = false;
          this.getList();
          // this.$set(item, name, false);
          // this.getList();
        }
      });
    },
  },
};
</script>
